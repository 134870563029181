<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">
          Tài khoản này chưa được kích hoạt
        </h4>
        <button
          type="button"
          class="btn btn-primary"
          @click="resendConfirmLink"
        >
          GỬI LẠI XÁC NHẬN
        </button>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      userId: this.$route.params.userId,
      email: "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  methods: {
    async resendConfirmLink() {
      try {
        this.loading.isLoading = true;
        const response = await UserService.resendConfirmLink(this.userId);
        if (response.code === "SUCCESS") {
          this.email = response.data.email;
          if (this.currentQueries) {
            this.$router.push({
              path: `/signup-mail/${this.userId}${this.currentQueries}&email=${this.email}`,
            });
          } else {
            this.$router.push({
              path: `/signup-mail/${this.userId}?email=${this.email}`,
            });
          }
        } else {
          alert(response.data.code);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
  },
};
</script>
